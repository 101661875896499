<template>
  <div
    class="u-display-flex u-flex-align-items-center u-spacing-mh-l u-spacing-pv-m u-color-grey-lighter"
    :class="customClassArray"
  >
    <rb-icon
      class="u-flex-0 rb-icon--medium u-spacing-mr-xs"
      :icon="'info-circle-fill'"
    />
    <div
      class="u-font-size-5 u-display-flex u-flex-align-items-center u-spacing-mt-xs"
    >
      We acknowledge the data mismatch between Citrus UI and CIQ UI between Jan
      18th to Jan 21st. We are working closely with Citrus team for a quick
      resolution.
    </div>
  </div>
</template>

<script>
export default {
  name: 'DataMismatchBanner',
  props: {
    customClassArray: {
      type: Array,
      default: function () {
        return [];
      }
    }
  }
};
</script>

<style lang="css" scoped>
.u-flex-align-self-flex-center {
  align-self: center;
}

.u-margin-right-auto {
  margin-right: auto;
}
</style>
